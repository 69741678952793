
import ModalWrapper from '@/components/modals/ModalWrapper.vue';
import { computed, defineComponent, onMounted, ref, watch } from 'vue';
import MasteringSheet from "@/services/MasteringSheet"
import prices from "@/services/prices"
import BaseCheckbox from "@/components/ui/BaseCheckbox.vue";
import BaseIcon from '@/components/ui/BaseIcon.vue';
import SearchDropdown from '@/components/search/SearchDropdown.vue';
import SmallSpinner from '@/components/SmallSpinner.vue';
import {ConvertDate} from "@/components/converters/date";
import { GetUserName } from "@/services/authentication";
import router from "@/router";
import articles from "@/services/articles";
import vehicles from "@/services/vehicles";
import {MessageModalMutationTypes} from "@/store/modules/message-modal/mutation-types";
import vehicleBrands from "@/services/vehicleBrands";
import CustomSelectModal from '@/components/ui/CustomSelectModal.vue';
import country from '@/services/country';

export default defineComponent({
  inheritAttrs: false,
  name: 'BrandEditModal',
  components: { ModalWrapper, BaseCheckbox, SearchDropdown, SmallSpinner, BaseIcon, CustomSelectModal },
  data: () => ({
    masteringSheets: null as any,
    selectedMasteringSheet: null as any,
    priceInfo: {} as any,
    activeMenu: 'sheets' as string,
    menu: [
      {key: 'sheets', value: 'Листы освоения'},
      {key: 'lists', value: 'Листы проценки'},
    ] as object,
    listOfArticles: [] as any,
    isLoading: false as boolean,
    searchResult: [] as any,
    showResults: false as boolean,
    error: '' as string,
    newBrand: {} as any,
    countries: [] as any,
    selectedCountry: null as any,
    allCountries: [] as any,
    copyAllCountries: [] as any
  }),
  props: {
    brand: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    convertDate() {
      return (date: any) => {
        return ConvertDate(date)
      }
    },
    getUserName() {
      return (id: number) => {
        return GetUserName(id)
      }
    },
    getFieldName() {
      return (key: string) => {
        switch (key) {
          case 'id':
            // return 'ID (' + key + ')'
            return ''
            break;
          case 'supplier':
            // return 'Источник (' + key + ')'
            return ''
            break;
          case 'brand':
            return 'Бренд (' + key + ') - '
            break;
          case 'brandCustom':
            return 'Наш бренд (' + key + ') - '
            break;
          case 'braCstTermNo':
            // return 'ModCstTermNo (' + key + ')'
            return ''
            break;
          case 'lastUpdate':
            // return 'Дата последнего изменения (' + key + ')'
            return ''
          case 'country':
            return 'Страна - '
            break;
          default:
            break;
        }
      }
    },
    canEdit() {
      return (key: string) => {
        switch (key) {
          case 'brand':
            return false
            break;
          case 'brandCustom':
            return true
            break;
          case 'country':
            return true
            break;
          default:
            return false
            break;
        }
      }

    }
  },
  methods: {
    selectCountry(countryName: string) {
      if (countryName === 'CLEAR_SELECTED') {
        this.newBrand.country = null
        return;
      }

      this.newBrand.country = countryName
    },
    searchCountry(event: any) {
      this.allCountries = [...this.copyAllCountries]
      this.allCountries = this.allCountries.filter((country: string) => country.includes(event.target.value))
    },
    async getPricesForList(id: number) {
      this.priceInfo = await prices.getPricesForMasteringSheet(id);
      this.$emit('setNewInfo')
    },
    checkItem(itemIndex: number) {
      this.masteringSheets.forEach((ms: any, index: number) => {
        if (index === itemIndex) {
          ms.checked = !ms.checked
          if (ms.checked) this.selectedMasteringSheet = ms
          if (!ms.checked) this.selectedMasteringSheet = null
        } else {
          ms.checked = false
        }
      })
    },
    goToSheet(id: number) {
      this.$router.push(`/price/sheet/${id}`);
      let element = document.getElementById(`modal-price-list`)!;
      element.style.display = 'none'
    },
    goToLists() {
      this.$router.push(`/price/list/${this.listOfArticles.map((a: any) => a.articleId || a.id).join('&')}`);
      let element = document.getElementById(`modal-price-list`)!;
      element.style.display = 'none'
    },
    setActiveMenu(item: any): any {
      this.activeMenu = item.key
    },
    addToList(item: any) {
      if (!!item && !this.listOfArticles.some((article: any) => article.id === item.id)) {
        this.listOfArticles.push(item)
      }
    },
    async search(item: any) {
      this.searchResult = [];
      if (item.target.value.length > 1 && item.target.value.length <= 20) {
        try {
          this.isLoading = true;
          let result = await articles.findArticleByName(item.target.value);
          if (!!result.length) {
            this.searchResult = result.map((o: any) => o.article);
            this.showResults = true;
          }
          if (!result.length) this.error = 'Данные не найдены'
        } catch (error) {
          error.value = 'Ошибка сервера';
          throw error
        }
        this.isLoading = false;
      } else {
        // this.searchResult.value = []
      }
    },
    deleteFromList(index: number) {
      this.listOfArticles.splice(index, 1)
    },
    async saveVehicleBrand() {
      const payload = {
        tecdocId: this.brand.tecdocId,
        supplierId: this.brand.supplierId,
        brand: this.brand.brand,
        displayName: this.brand.displayName,
        braCstTermNo: this.brand.braCstTermNo,
        visible: this.brand.visible,
        brandCustom: this.newBrand.brandCustom,
        country: this.newBrand.country
      }

      await vehicleBrands.updateBrand(this.brand.id, payload);
      let element = document.getElementById(`modal-brand-edit`)!;
      element.style.display = 'none'
      this.$emit('reload')
      setTimeout(() => {
        this.$store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'SUCCESS')
      }, 100)
    },
    async updateData() {
      this.newBrand.country = null

      this.newBrand.brand = this.brand.brand;
      this.newBrand.brandCustom = this.brand.brandCustom
      this.allCountries = await country.getAllCountries()
      this.copyAllCountries = this.allCountries

      this.newBrand.country = this.brand.country
    },
    closeModal() {
      this.newBrand.country = null
      this.newBrand.brand = null
      this.newBrand.brandCustom = null
    }
  },
  async created() {
    this.updateData()
  },
  async updated() {
    this.updateData()
  },
})
