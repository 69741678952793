
  import ModalWrapper from '@/components/modals/ModalWrapper.vue';
  import { computed, defineComponent, onMounted, ref, watch } from 'vue';
  import prices from "@/services/prices"
  import BaseCheckbox from "@/components/ui/BaseCheckbox.vue";
  import BaseIcon from '@/components/ui/BaseIcon.vue';
  import SearchDropdown from '@/components/search/SearchDropdown.vue';
  import SmallSpinner from '@/components/SmallSpinner.vue';
  import {ConvertDate} from "@/components/converters/date";
  import { GetUserName } from "@/services/authentication";
  import articles from "@/services/articles";
  import {MessageModalMutationTypes} from "@/store/modules/message-modal/mutation-types";
  import vehicleBrands from "@/services/vehicleBrands";

  export default defineComponent({
    inheritAttrs: false,
    name: 'BrandCreateModal',
    components: { ModalWrapper, BaseCheckbox, SearchDropdown, SmallSpinner, BaseIcon },
    data: () => ({
      masteringSheets: null as any,
      selectedMasteringSheet: null as any,
      priceInfo: {} as any,
      activeMenu: 'sheets' as string,
      menu: [
        {key: 'sheets', value: 'Листы освоения'},
        {key: 'lists', value: 'Листы проценки'},
      ] as object,
      listOfArticles: [] as any,
      isLoading: false as boolean,
      searchResult: [] as any,
      showResults: false as boolean,
      error: '' as string,
      newBrand: {} as any
    }),
    props: {
      brand: {
        type: Object,
        default: () => {}
      }
    },
    computed: {
      convertDate() {
        return (date: any) => {
          return ConvertDate(date)
        }
      },
      getUserName() {
        return (id: number) => {
          return GetUserName(id)
        }
      },
      getFieldName() {
        return (key: string) => {
          switch (key) {
            case 'id':
              // return 'ID (' + key + ')'
              return ''
              break;
            case 'supplier':
              // return 'Источник (' + key + ')'
              return ''
              break;
            case 'brand':
              return 'Бренд (' + key + ') - '
              break;
            case 'braCstTermNo':
              // return 'ModCstTermNo (' + key + ')'
              return ''
              break;
            case 'lastUpdate':
              // return 'Дата последнего изменения (' + key + ')'
              return ''
              break;
            default:
              break;
          }
        }
      },
      canEdit() {
        return (key: string) => {
          switch (key) {
            case 'brand':
              return true
              break;
            default:
              return false
              break;
          }
        }

      }
    },
    methods: {
      async setDefaultData() {
        // this.masteringSheets = await MasteringSheet.getSheets();
        // if (this.tablePrice && this.tablePrice.length) {
        //   this.tablePrice.forEach((article: any) => this.listOfArticles.push(article.articleFull))
        // }
      },
      async getPricesForList(id: number) {
        console.log('getPricesForList');
        this.priceInfo = await prices.getPricesForMasteringSheet(id);
        console.log(this.priceInfo);
        this.$emit('setNewInfo')
      },
      checkItem(itemIndex: number) {
        this.masteringSheets.forEach((ms: any, index: number) => {
          if (index === itemIndex) {
            ms.checked = !ms.checked
            if (ms.checked) this.selectedMasteringSheet = ms
            if (!ms.checked) this.selectedMasteringSheet = null
          } else {
            ms.checked = false
          }
        })
      },
      goToSheet(id: number) {
        this.$router.push(`/price/sheet/${id}`);
        let element = document.getElementById(`modal-price-list`)!;
        element.style.display = 'none'
      },
      goToLists() {
        this.$router.push(`/price/list/${this.listOfArticles.map((a: any) => a.articleId || a.id).join('&')}`);
        let element = document.getElementById(`modal-price-list`)!;
        element.style.display = 'none'
      },
      setActiveMenu(item: any): any {
        this.activeMenu = item.key
      },
      addToList(item: any) {
        if (!!item && !this.listOfArticles.some((article: any) => article.id === item.id)) {
          this.listOfArticles.push(item)
        }
      },
      async search(item: any) {
        // error.value = '';
        this.searchResult = [];
        if (item.target.value.length > 1 && item.target.value.length <= 20) {
          console.log('start search')
          try {
            this.isLoading = true;
            let result = await articles.findArticleByName(item.target.value);
            if (!!result.length) {
              this.searchResult = result.map((o: any) => o.article);
              this.showResults = true;
            }
            if (!result.length) this.error = 'Данные не найдены'
          } catch (error) {
            error.value = 'Ошибка сервера';
            throw error
          }
          this.isLoading = false;
        } else {
          // this.searchResult.value = []
        }
      },
      deleteFromList(index: number) {
        this.listOfArticles.splice(index, 1)
      },
      async addVehicleBrand() {
        const payload = {
          tecdocId: 1,
          supplierId: this.newBrand.supplier.id,
          brand: this.newBrand.brand,
          brandCustom: this.newBrand.brand,
          braCstTermNo: 0
        }

        await vehicleBrands.addNewBrand(payload)
        let element = document.getElementById(`modal-brand-create`)!;
        element.style.display = 'none';
        this.$emit('reload');
        setTimeout(() => {
          this.$store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'SUCCESS')
        }, 100)
      }
    },
    async mounted() {
      this.newBrand = this.brand
      this.newBrand.brand = ''
      // if (this.tablePrice && this.tablePrice) {
      //   this.activeMenu = 'lists'
      // }
      // await this.setDefaultData()
    }
  })
