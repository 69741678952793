
import {defineComponent, ref, computed, onMounted, watch} from 'vue';
import {useStore} from '@/store'
import router from "@/router";
import {VehicleMutationTypes} from "@/store/modules/vehicles/mutation-types";
import BaseIcon from '@/components/ui/BaseIcon.vue';
import Spinner from '@/components/Spinner.vue';
import vehicles from '@/services/vehicles';
import {useRoute} from 'vue-router';
import HeaderVehicleInfo from '@/components/ui/HeaderVehicleInfo.vue';
import BaseCheckbox from '@/components/ui/BaseCheckbox.vue';
import Breadcrumbs from '@/components/ui/Breadcrumbs.vue';
import AddVehicleModal from '@/components/modals/AddVehicleModal.vue';
import BrandCreateModal from "@/components/modals/BrandCreateModal.vue";
import BrandEditModal from "@/components/modals/BrandEditModal.vue";
import {roles} from "@/services/roles/serviceRoles";
import vehicleBrands from "@/services/vehicleBrands";
import routerService from "@/services/routerService";
import DropdownSearchCommodityGroups
  from "@/components/dropdown/DropdownSearchCommodityGroups.vue";
import BaseInput from "@/components/ui/BaseInput.vue";
export default defineComponent({
  name: 'BrandsTable',
  components: {
    AddVehicleModal,
    Breadcrumbs,
    BaseCheckbox,
    HeaderVehicleInfo,
    BaseIcon,
    Spinner,
    BrandCreateModal,
    BrandEditModal,
    DropdownSearchCommodityGroups,
    BaseInput
  },
  data: () => ({
    isDropdownRevealed: false as boolean,
    vehicleStatePick: 'all' as any,
    pickedBrand: {} as any,
    checked: [] as any,
    showAll: false,
    error: false,
    isLoading: true as boolean,
    vehiclesBrands: [] as any,
    allVehicleBrands: [] as any,
    typeEnums: {
      moto: 'MOTORBIKE',
      light: 'PASSENGER',
      commerce: 'COMMERCIAL',
      heavy: 'TRUCK_AND_BUS',
      agro: 'TRACTOR_AND_SPECIAL'
    } as object,
    isVehicleBlocked: false as boolean,
  }),
  computed: {
    filteredVehicleBrandsByAlphabet(): any{
      return this.arr_EN.filter((letter: any) => {
        return this.vehiclesBrands.some((vehicleBrand: any) => {
          const firstBrandLetter = (vehicleBrand.brand || '').toUpperCase().charAt(0)
          return firstBrandLetter === letter
        })
      })
    },
    currentUserCanAddNewVehicle(): any {
      return this.$store.getters.getAllPermissions.add_new_vehicles[this.$store.getters.getUserRole]
    },
    userViewBlockedUnblockedVehicle(): any {
      return this.$store.getters.getAllPermissions.blocked_vehicles[this.$store.getters.getUserRole]
    },
    getVehicleStateByRouteParam(): any {
      return this.$route.query.vehicleState
    },
    getPath() {
      let query = '';
      if (this.$route.query.vehicleState && this.$route.query.vehicleType) {
        query = `?vehicleState=${this.$route.query.vehicleState}&vehicleType=${this.$route.query.vehicleType}`
      }
      if (this.$route.query.vehicleType && !this.$route.query.vehicleState) {
        query = `?vehicleType=${this.$route.query.vehicleType}`
      }
      return (id: string) => `/cars/brands/${id}/models${query}`
    },
    getCurrentCrumbs(): any {
      const vehicleTypes = this.$store.getters.getVehicleTypes;

      let crumbNameCarsRoute = 'Поиск';
      let crumbNameCarsRouteLink = '/cars';

      if (this.$route.query.vehicleState) {
        switch (this.$route.query.vehicleState) {
          case 'actual':
            crumbNameCarsRoute = 'Актуальные'
            break;
          case 'blocked':
            crumbNameCarsRoute = 'Заблокированные'
            break;
          case 'all':
            crumbNameCarsRoute = 'Все'
            break;
        }

        crumbNameCarsRouteLink = `/cars?vehicleState=${this.$route && this.$route.query.vehicleState}`
      }
      const currentVehicleTypeName: any = vehicleTypes.filter((type: any) => type.key === this.$route.query.vehicleType)
      if (currentVehicleTypeName && currentVehicleTypeName.length) {
        return [
          {
            name: crumbNameCarsRoute,
            link: crumbNameCarsRouteLink
          },
          {
            name: currentVehicleTypeName[0].value,
            link: null
          }
        ]
      } else {
        return []
      }

    }
  },
  methods: {
    searchBrand(value: string) {
      this.vehiclesBrands = this.allVehicleBrands
      this.vehiclesBrands = this.vehiclesBrands.filter((vehicleBrand: any) => {
        const brand = (vehicleBrand.brand || '').toLowerCase()
        const customBrand = (vehicleBrand.brandCustom || '').toLowerCase()
        return brand.includes(value.toLowerCase()) ||
          customBrand.toLowerCase().includes(value.toLowerCase())
      })
    },
    openAddVehicleModal() {
      let element = document.getElementById(`modal-vehicle-add`)!
      element.style.display = 'flex'
    },
    goToSearch() {
      this.$router.push({name: 'cars_search', params: {}})
    },
    setQuery(query: string) {
      routerService.setQuery('vehicleState', query, this.$route.query, this.$router)
    },
    setChecked(id: any) {
      let index = this.checked.findIndex((o: string) => o === id);
      if (index !== -1) {
        this.checked.splice(index, 1)
      } else {
        this.checked.push(id)
      }
    },

    changeBlockStatus() {
      if (this.checkQuery && String(this.checkQuery) === 'actual') {
        this.setBlockedBrand()
      } else if (this.checkQuery && String(this.checkQuery) === 'blocked')
        this.setUnblockedBrand()
    },

    async setBlockedBrand() {
      if (this.checked.length) {
        this.isLoading = true;
        await this.setLockBrand('blocked');
        this.setVehiclesBrands()
      }
      this.checked = []
    },

    async setLockBrand(lock: string) {
      await Promise.all(this.checked.map(async (brandId: number) => {
        if (lock === 'blocked') {
          await vehicleBrands.setBlockedBrand(brandId)
        } else if (lock === 'unblocked') {
          await vehicleBrands.setUnblockedBrand(brandId)
        }
      }));
    },

    async setUnblockedBrand() {
      if (this.checked.length) {
        this.isLoading = true;
        await this.setLockBrand('unblocked');
        this.setVehiclesBrands()
      }
      this.checked = []
    },

    setVehiclesBrands() {
      if (this.$route.query.vehicleType === 'moto') {
        vehicleBrands.findAllBrandsByType('MOTORBIKE', this.isVehicleBlocked).then(res => {
          this.vehiclesBrands = res;
          this.allVehicleBrands = this.vehiclesBrands
          this.isLoading = false
        })
      }
      if (this.$route.query.vehicleType === 'light') {
        vehicleBrands.findAllBrandsByType('PASSENGER', this.isVehicleBlocked).then(res => {
          this.vehiclesBrands = res;
          this.allVehicleBrands = this.vehiclesBrands
          this.isLoading = false
        })
      }
      if (this.$route.query.vehicleType === 'commerce') {
        vehicleBrands.findAllBrandsByType('COMMERCIAL', this.isVehicleBlocked).then(res => {
          this.vehiclesBrands = res;
          this.allVehicleBrands = this.vehiclesBrands
          this.isLoading = false
        })
      }
      if (this.$route.query.vehicleType === 'heavy') {
        vehicleBrands.findAllBrandsByType('TRUCK_AND_BUS', this.isVehicleBlocked).then(res => {
          this.vehiclesBrands = res;
          this.allVehicleBrands = this.vehiclesBrands
          this.isLoading = false
        })
      }
      if (this.$route.query.vehicleType === 'agro') {
        vehicleBrands.findAllBrandsByType('TRACTOR_AND_SPECIAL', this.isVehicleBlocked).then(res => {
          this.vehiclesBrands = res;
          this.allVehicleBrands = this.vehiclesBrands
          this.isLoading = false
        })
      }
      if (this.$route.query.vehicleType === 'all_categories') {
        const isBlocked = this.$route.query.vehicleState === 'all'
          ? 'ALL'
          : this.$route.query.vehicleState === 'blocked'
            ? true
            : false
        vehicleBrands.getAllVehiclesBrands(isBlocked).then(res => {
          this.vehiclesBrands = res;
          this.allVehicleBrands = this.vehiclesBrands
          this.isLoading = false
        })
      }
      // if (this.$route.name === 'brands') {
      //   this.getAllVehiclesBrands()
      // }
      if (this.vehiclesBrands.length && this.vehiclesBrands.length < 80) this.showAll = true
    },

    getAllVehiclesBrands(vehicleType: any) {
      if(vehicleType === 'all_categories') {
        const isBlocked = this.$route.query.vehicleState === 'all'
          ? 'ALL'
          : this.$route.query.vehicleState === 'blocked'
            ? true
            : false

        vehicleBrands.getAllVehiclesBrands(isBlocked).then(res => {
          this.vehiclesBrands = res;
          this.allVehicleBrands = res
          this.isLoading = false
        })
        return;
      }

      const vehicleTypes = {
        'agro': 'TRACTOR_AND_SPECIAL',
        'heavy': 'TRUCK_AND_BUS',
        'commerce': 'COMMERCIAL',
        'light': 'PASSENGER',
        'moto': 'MOTORBIKE',
      } as any

      vehicleBrands.getAllVehiclesBrands('ALL', vehicleTypes[vehicleType]).then(res => {
        this.vehiclesBrands = res;
        this.allVehicleBrands = res
        this.isLoading = false
      })
    },

    openAddBrandModal() {
      let element = document.getElementById(`modal-brand-create`)!;
      element.style.display = 'flex'
    },

    openEditModal(brand: any) {
      this.pickedBrand = brand;
      setTimeout(() => {
        let element = document.getElementById(`modal-brand-edit`)!;
        element.style.display = 'flex'
      }, 100)
    },

    setDefaultBrands() {
      this.setVehiclesBrands()
    },
    checkBlockedVehicle() {
      if (this.$route.query?.vehicleState === 'all' && this.$route.query?.vehicleType) {
        this.getAllVehiclesBrands(this.$route.query?.vehicleType)
      } else if (this.$route.query && this.$route.query.vehicleState !== 'all') {
        switch (this.$route.query.vehicleState) {
          case 'actual':
            this.$store.commit(VehicleMutationTypes.SET_VEHICLES_BLOCKED, false);
            this.isVehicleBlocked = false;
            break;
          case 'blocked':
            this.$store.commit(VehicleMutationTypes.SET_VEHICLES_BLOCKED, true);
            this.isVehicleBlocked = true;
            break;
          default:
            this.$store.commit(VehicleMutationTypes.SET_VEHICLES_BLOCKED, false)
        }

        this.setVehiclesBrands()
        // if (this.$route.query.vehicleType) {
        //
        // } else {
        //   this.getAllVehiclesBrands()
        // }
      }
    }
  },
  async mounted() {
    this.checkBlockedVehicle()
  },
  setup() {
    const route = useRoute();
    const store = useStore();
    // const checkQuery = computed(() => {
    //   return roles.edit_blocked_unblocked[store.getters.getUserRole]
    //       ? route.query.vehicleState === 'all'
    //         ? false
    //         : route.query.vehicleState
    //       : false
    // })
    const checkQuery = computed(() => {
      return store.getters.getAllPermissions.blocked_vehicles[store.getters.getUserRole]
        ? route.query.vehicleState === 'all'
          ? false
          : route.query.vehicleState
        : false
    })
    const arr_EN = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'А', 'Б', 'В', 'Г', 'Д', 'Е', 'Ё', 'Ж', 'З', 'И', 'Й', 'К', 'Л', 'М', 'Н', 'О', 'П', 'Р', 'С', 'Т', 'У', 'Ф', 'Х', 'Ц', 'Ч', 'Ш', 'Щ', 'Э', 'Ю', 'Я'];
    const allBrands: { [x: string]: any } = {};
    const pop_Brands =
      [
        'LADA',
        'KIA',
        'HYUNDAI',
        'RENAULT',
        'VAG',
        'TOYOTA',
        'TESLA',
        'NISSAN',
        'SKODA',
        'FORD',
        // 'GAZ',
        'MITSUBISHI',
        'MERCEDES',
        // 'UAZ',
        'BMW',
        'CHEVROLET',
        // 'DATSUN',
        'MAZDA',
        'RAVON',
        'LEXUS',
        'AUDI',
        'LIFAN',
        'ROVER',
        'SUBARU',
        'PEUGEOT',
        'INFINITI',
        'CHERY',
        'CITROËN',
        'SUZUKI',
        'VOLVO',
        'HONDA',
        'PORSCHE',
        'MINI',
        'JAGUAR',
        // 'ZOTYE',
        'HAVAL',
        // 'DFM',
        // 'GEELY',
        'JEEP',
        'FIAT',
        'CADILLAC',
        'CHRYSLER'
      ];



    onMounted(async () => {
      let loggedUser = localStorage.getItem('loggedUser');
      let cid = localStorage.getItem('cid');
      if (loggedUser && cid) {
      } else {
        await router.push('login')
      }
    });
    return {
      arr_EN,
      pop_Brands,
      checkQuery,
    }
  },
  watch: {
    '$route.query.vehicleState': function() {
      // this.checkBlockedVehicle()
      this.isLoading = true
      if (this.$route.query?.vehicleState === 'all') {
        this.getAllVehiclesBrands(this.$route.query.vehicleType)
        return;
      }

      if (this.$route.query?.vehicleState === 'actual') {
        this.isVehicleBlocked = false
      } else if (this.$route.query?.vehicleState === 'blocked') {
        this.isVehicleBlocked = true
      }

      this.setVehiclesBrands()
    }
  }
})
